import {LOCALES} from '../constants';

export default {
    [LOCALES.GERMAN]: {
        hello: 'Hello Nachricht',
        keyboard: 'Keyboard Nach',
        keycap: 'Keycap Nach',
        readme: 'Read Me Nach',
    }
}


